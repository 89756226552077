import React from "react"
import RealizationInfo from "../../components/Organisms/RealizationInfo/RealizationInfo"
import RealizationPhotos from "../../components/Molecules/realizationPhotos/RealizationPhotos"
import { graphql } from "gatsby"
import photo1 from "../../assets/images/realizations/lomza/1.jpg"
import photo2 from "../../assets/images/realizations/lomza/2.jpg"
import photo3 from "../../assets/images/realizations/lomza/3.jpg"
import preili from "../../assets/images/realizations/preili/preili.jpg"
import bialystok1 from "../../assets/images/realizations/bialystok1/bialystok1-1.jpg"
import bialystok2 from "../../assets/images/realizations/bialystok2/bialystok-2.jpg"
import OtherRealizations from "../../components/Organisms/OtherRealizations/OtherRealizations"
import CTABanner from "../../components/Organisms/CTABanner/CTABanner"
import { WithIntl } from "../../components/Atoms/withTranslate"
import { localize } from "../../components/Atoms/Language"


const LomzaPage = ({ data, language }) => {
  const images = [photo1, photo2, photo3]
  const otherRealizations = [
    {
      image: preili,
      name: localize("293"),
      type: localize("296"),
      url: "preili",
    },
    {
      image: bialystok1,
      name: localize("183"),
      type: localize("186"),
      url: "bialystok-1",
    },
    {
      image: bialystok2,
      name: localize("184"),
      type: localize("187"),
      url: "bialystok-2",
    },
  ]

  return (
    <>
      <RealizationInfo closed={2} name={localize("292")} image={data.file.childImageSharp.fixed} language={language}>
        <li className="gray600">{localize("339")}</li>
        <li className="gray600">{localize("331")}</li>
        <li className="gray600">{localize("340")}</li>
        <li className="gray600">{localize("329")}</li>
      </RealizationInfo>
      <RealizationPhotos images={images}/>
      <OtherRealizations realizations={otherRealizations} language={language}/>
      <CTABanner language={language}/>
    </>
  )
}

export const query = graphql`
  {
    file(name: { eq: "lomza" }) {
      childImageSharp {
        fixed(width: 540, height: 400){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default WithIntl()(LomzaPage)
